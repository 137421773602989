<template>
    <div v-if="!recordStore.HideTotalPrice" class="w-100">
        <itinerary-prices></itinerary-prices>
    </div>
</template>

<script>
const itineraryPrices = importVueComp('components/itinerary', 'ItineraryPrices', 'custom');
export default {
    name: 'custom-itinerary-prices',
    mixins: [itineraryPrices],
    components: {
        'itinerary-prices': itineraryPrices,
    },
}
</script>

